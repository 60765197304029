import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import logo from "../assets/test-logo.png";
import { Card } from "reactstrap";

const Index = () => {
  return (
    <>
      <header>
        <div className="logo-bg">
          <img src={logo} width={500} height={500} alt="BuildHub logo" />
        </div>
      </header>

      <section>
        <p>The BuildHub app, designed for simplicity and ease of use, streamlines the planning and execution of construction projects. It empowers individuals without specialised knowledge, guiding them seamlessly from initial concept designs to project completion. By emphasising efficiency, communication, and organization, BuildHub ensures that even non-experts can navigate the construction process effortlessly.</p>
      </section>

      <section>
        <Card>
          <h2>Streamlined Communication</h2>
          <p>
            Ensure everyone is on the same page with real-time updates and
            integrated messaging systems.
          </p>
          <ul>
            <li>Real-time notification</li>
            <li>Automated progress updates</li>
            <li>Centralized communication hub</li>
          </ul>
        </Card>
        <Card>
          <h2>Enhanced Collaboration</h2>
          <p>
            Collaborate more effectively with tools that bring your team
            together, no matter where they are.
          </p>
          <ul>
            <li>Cloud-based access</li>
            <li>Shared project timelines</li>
            <li>Task delegation and monitoring</li>
          </ul>
        </Card>
        <Card>
          <h2>Improved Project Tracking</h2>
          <p>
            Keep your projects on track with tools designed to monitor progress
            and predict potential delays.
          </p>
          <ul>
            <li>Project checkpoints</li>
            <li>Deadline reminders</li>
            <li>Regular reviews</li>
          </ul>
        </Card>
      </section>

      <footer>
        <p>Coming soon!</p>
      </footer>
    </>
  );
};

export default Index;
